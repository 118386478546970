import React, { useState, useEffect } from "react";

import { Row, Col, Card, Spin,Button } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { aictia } from "../../../const/api";
import { useTranslation } from "react-i18next";

function Index() {
  const [data, setData] = useState([]);
  const [activityPie, setActivityPie] = useState({ labels: [], series: [] });
  const [companyPie, setCompanyPie] = useState({ labels: [], series: [] });
  const [activtyTypeSeries, setActivityTypeSeries] = useState([]);
  const [activtyCompanySeries, setCompanyTypeSeries] = useState([]);
  const [componyGrafikType, setComponyGrafikType] = useState(true);
  const [capitalGrafikType, setCapitalGrafikType] = useState(true); 

  const [activityYears, setActivityYears] = useState([]);
  const [companyActivityYears, setCompanyActivityYears] = useState([]);
  const [spin, setSpin] = useState(false);

  const { t } = useTranslation();
  const getData = () => {
    setSpin(true);
    aictia.get("Dashboard").then((res) => {
      setSpin(false);
      setData(res.data);
      const activityFields = res.data?.dashboard_GroupedData.groupOfActivity

      const companyType = res.data?.dashboard_GroupedData.groupOfTypeOfCompany
      const activityType = res.data?.statisticByYears.authorizedCapitalByYears
      const companySum = res.data?.statisticByYears.companyCountByYears

      if (activityFields) {
        const activityLabels = activityFields?.map((elem) => elem.name);
        const activitySeries = activityFields?.map((elem) => elem.count);
        console.log(activityLabels);
        setActivityPie({ activityLabels, activitySeries });
      }


      if (companyType) {
        const companyLabels = companyType?.map((elem) => {
          const name = elem.name === "İctimai birlik" ? "İ/B" : elem.name; // 'İctimai birlik' ise 'IB' yap
          return `${name}`;
        });

        const companySeries = companyType?.map((elem) => elem.count);
        setCompanyPie({ companyLabels, companySeries });
      }

      //if (activityType) {
      const allYears = [
        ...new Set(activityType.map((item) => item.years)),
      ];
      let cumulativeSum = 0; // Birikimli toplamı tutacak değişken
      let cumulativeSum1 = 0; // Birikimli toplamı tutacak değişken
      const cumulativeData = companySum.map(item => {
        cumulativeSum += item.count; // Birikimli toplama mevcut elemanın count'unu ekle
        return { ...item, cumulativeCount: cumulativeSum }; // Yeni bir nesne döndür
      });
      
      const cumulativeData1 = activityType.map(item => {
        console.log(item.sumAuthorizedCapital);
        
        cumulativeSum1 += item.sumAuthorizedCapital; // Birikimli toplama mevcut elemanın count'unu ekle
        return { ...item, cumulativeCount: cumulativeSum1 }; // Yeni bir nesne döndür
      });
      console.log(cumulativeData1);
      

      const yearsAfter2015 = capitalGrafikType ?  allYears
        .filter((year) => parseInt(year) >= 2015).reverse() : allYears
        .filter((year) => parseInt(year) >= 2015)
      const yearsBefore2015 = allYears
        .filter((year) => parseInt(year) < 2015).reverse();
      const yearsAfter2015Company = componyGrafikType ? allYears
        .filter((year) => parseInt(year) >= 2015).reverse() : allYears
        .filter((year) => parseInt(year) >= 2015)
      setCompanyActivityYears(yearsAfter2015Company);

      setActivityYears(yearsAfter2015);

      const names = [...new Set(cumulativeData1.filter(item => parseInt(item.years) >= 2015).map((item) => capitalGrafikType ? item.cumulativeCount :  item.sumAuthorizedCapital))];
      const names2 = [...new Set(activityType.filter(item => parseInt(item.years) >= 2015).map((item) => item.sumAuthorizedCapital))];
      const names1 = [...new Set(cumulativeData.filter(item => parseInt(item.years) >= 2015).map((item) => componyGrafikType ?  item.cumulativeCount : item.count))];
      console.log(names);
      const reverseName = capitalGrafikType ? names.reverse() : names;
      const reverseName1 = componyGrafikType ? names1.reverse() : names1;
      console.log(reverseName);
      
      setActivityTypeSeries(reverseName);
      setCompanyTypeSeries(reverseName1);

        const series = [];

        names1.forEach((name) => {
          const dataCounts = [];
          let count = 0;


          yearsBefore2015.forEach((year) => {
            const yearData = activityType.filter(
              (item) => item.years === year  && item.c === name
            );
            yearData.forEach((item) => (count += item.count));
          });


          yearsAfter2015.forEach((year) => {
            const yearData = activityType.filter(
              (item) => item.years === year && item.name === name
            );
            yearData.forEach((item) => (count += item.count));
            dataCounts.push(count);
          });

          series.push({
            name: name,
            data: dataCounts.reverse(),
          });
        });
console.log(
  series
);


        // setActivityTypeSeries(series);
        // setActivityYears(yearsAfter2015.reverse());
      

    });
  };
  console.log(activtyTypeSeries);
  


  useEffect(() => {
    getData();
  }, [componyGrafikType,capitalGrafikType]);

  const series = {
    data: [{
      x: 'category A',
      y: 10
    }, {
      x: 'category B',
      y: 18
    }, {
      x: 'category C',
      y: 13
    }]

  }

  const activityField = {
    options: {
      labels: activityPie.activityLabels || [],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -30,
            enabled: false, // Yüzdelik değerleri tamamen kaldır
          },
        },
      },
      dataLabels: {
        enabled: false, // Ek olarak global dataLabels'i kapat
      },
      chart: {
        background: 'transparent', // Şeffaf arka plan
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 50,
        marginRight: 50,
      },
      stroke: {
        show: false, // Çevre kenar çizgilerini kaldır
      },
      legend: {
        position: 'right', // Legend'leri grafiğin altına taşır
        fontSize: '12px',
        offsetY: 10,
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + " (" + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) + "%) ";
        }

      },
    },
    series: activityPie.activitySeries || [],
  };

  const typeOfCompany = {
    options: {
      labels: companyPie.companyLabels || [],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -30,
            enabled: false, // Yüzdelik değerleri tamamen kaldır
          },
        },
      },
      dataLabels: {
        enabled: false, // Ek olarak global dataLabels'i kapat
      },
      chart: {
        background: 'transparent', // Şeffaf arka plan
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 50,
        marginRight: 50,
      },
      stroke: {
        show: false, // Çevre kenar çizgilerini kaldır
      },
      legend: {
        position: 'right', // Legend'leri grafiğin altına taşır
        fontSize: '12px',
        offsetY: 10,
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + " (" + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(1) + "%)";
        }
      },
    },
    series: companyPie.companySeries || [],
  };
  console.log(activtyTypeSeries);

  const responsive = window.innerWidth < 2000 && window.innerWidth > 1700 ? true : false;
  const state = {
    options: {
      chart: {
        id: "basic-bar",
        background: 'transparent', // Şeffaf arka plan
        toolbar: {
          show: false, // Toolbar'i kaldır
        },
      },
      plotOptions: {
        bar: {
          horizontal: true, // Çubukları yatay yap
        },
      },
      xaxis: {
        categories: activityYears,
        labels: {
          formatter: function (val) {

            // Sayıları kısaltarak göster
            if (capitalGrafikType && val >= 1000000) {
              return (val / 1000000) + "M"; // Milyonlar için
            } else if (capitalGrafikType && val >= 1000) {
              return (val / 1000) + "K"; // Binler için
            }
            return val; // Küçük sayılar
          },
        },
    
      },
      yaxis:{
        labels: {
          formatter: function (val) {

            // Sayıları kısaltarak göster
            if (!capitalGrafikType && val >= 1000000) {
              return (val / 1000000) + "M"; // Milyonlar için
            } else if (!capitalGrafikType && val >= 1000) {
              return (val / 1000) + "K"; // Binler için
            }
            return val; // Küçük sayılar
          },
        },
      },

      dataLabels: {
        enabled: false, // Barların üzerindeki yazıları gizle
      },
    },
    series: [
      {
        name: "Ümumi nizamnamə kapitalı",
        data: activtyTypeSeries,
      }
    ]
  };
  const state1 = {
    options: {
      chart: {
        id: "basic-bar",
        background: 'transparent', // Şeffaf arka plan
        toolbar: {
          show: false, // Toolbar'i kaldır
        },
      },
      plotOptions: {
        bar: {
          horizontal: true, // Çubukları yatay yap
        },
      },
      xaxis: {
        categories: companyActivityYears,
      
      },


      dataLabels: {
        enabled: false, // Barların üzerindeki yazıları gizle
      },
    },
    series: [
      {
        name: "Şirkət sayı",
        data: activtyCompanySeries,
      }
    ]
  };

  console.log(componyGrafikType);


  return (
    <div className="dashboard">
      <Col xs={24}>
        <div className="border animated fadeInDown p-2 mt-0 bg-white">
          <BarChartOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">Statistika</span>
        </div>
      </Col>
      {spin ? (
        <div className="flex flex-align-center justify-center mt-15">
          <Spin />
        </div>
      ) : (
        <>
          <Col className="chart-pie">

            <div className="flex flex-between gap-6">
              <div className="company">
                <p>Şirkətlər</p>

                <CountUp end={data?.generalIndicators?.countOfCompanies} separator=" " />
              </div>
              <div className="company">
                <p>Bağlanmış şirkətlər</p>

                <CountUp end={data?.generalIndicators?.countOfDeactiveCompanies} separator=" " />
              </div>
              <div className="company">
                <p>Nizamnamə kapitalı</p>
                <CountUp
                  end={data?.generalIndicators?.sumOfAuthorizedCapitalActiveCompanies}
                  separator=" "
                />
              </div>
              <div className="company">
                <p>Əməkdaşlar</p>
                <CountUp
                  end={data?.generalIndicators?.sumOfEmployeeActiveCompanies}
                  separator=" "
                />
              </div>

            </div>
            <div className="flex flex-between">
              <div>
                <h2 className="title">Fəaliyyət sahəsinə görə</h2>
                <div className="mixed-chart">
                  <Chart
                    options={activityField.options}
                    series={activityField.series}
                    type="pie"
                    width={responsive ? "600" : "500"}
                    height={responsive ? "600" : "500"}


                  />
                </div>
              </div>


              <div >
               <div>
           
                <h2 className="title"> {t('businessEntity')}</h2>
                </div>
                <div className="mixed-chart">
                  <Chart
                    options={typeOfCompany.options}
                    series={typeOfCompany.series}
                    type="pie"
                    width={responsive ? "600" : "500"}
                    height={responsive ? "600" : "500"}
                  />
                </div>
              </div>

            </div>
          </Col>

          <div className="row1">
            <div className=" chart-div">
              <div className="chart">
              <div className="flex flex-align-center flex-between mb-20">
              <div className="flex dir-column gap-4">
                  <Button style={{backgroundColor:'#091a3f',color:'#fff'}} onClick={() => setComponyGrafikType(true)}> Şirkətlərin sayı</Button>
                 
                </div>
                <Button style={{backgroundColor:'#8c52ff',color:'#fff'}} onClick={() => setComponyGrafikType(false)}> Şirkətlərin Dinamikası</Button>
                {/* <h2 className="title">{ componyGrafikType ? t('Şirkətlərin sayı') : 'Şirkətlərin Dinamikası'}</h2> */}
                </div>
        
                <Chart
                  options={state1.options}
                  key={componyGrafikType}
                  series={state1.series}
                  type={componyGrafikType ? "bar" : "line"}
                  height={550}
                />
              </div>
            </div>
            <div className=" chart-div">
              <div className="chart">
              <div className="flex flex-align-center flex-between mb-20">
              <div className="flex dir-column gap-4">
                  <Button style={{backgroundColor:'#091a3f',color:'#fff'}} onClick={() => setCapitalGrafikType(true)}> Nizamnamə kapitalı</Button>
                 
                </div>
                <Button style={{backgroundColor:'#8c52ff',color:'#fff'}} onClick={() => setCapitalGrafikType(false)}> Nizamnamə kapitalın Dinamikası</Button>
                {/* <h2 className="title">{ capitalGrafikType ? t('Nizamnamə kapitalı') : 'Nizamnamə kapitalın Dinamikası'}</h2> */}
                </div>


                <Chart
                  options={state.options}
                  key={capitalGrafikType}
                  series={state.series}
                  type={capitalGrafikType ? "bar" : "line"}
                  height={550}
                />
              </div>
            </div>

          </div>
        </>
      )}
    </div>
  );
}

export default Index;
