
import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Row,
  Col,
  Table,
  Spin,
  Select,
  Form,
  Input,
  DatePicker,
  Pagination
} from "antd";
import { convertColumns } from "../../../../utils/columnconverter";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import {
  ClearOutlined,
  ContainerOutlined,
  SyncOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Popconfirm, Tooltip } from "antd";

import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../../../const/api";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const { RangePicker } = DatePicker;
function History(props) {
  let mainUrl = "ReyestrOfCompany";
  const [form] = Form.useForm();
  const [log, setLog] = useState([]);
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [input, setInput] = useState(null);
  const [user, setUser] = useState([]);
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const { id } = useParams();
  const { t } = useTranslation();
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    {
      key: "userName",
      value: t("İstifadəçi"),
      con: true,
    },
    {
      key: "roleName",
      value: "Səlahiyyəti",
      con: true,
    },
    { key: "companyName", value: "Şirkətin adı", con: false },
    { key: "operationName", value: "Əməliyyatın adı", con: false },

    { key: "operationDate", value: "Əməliyyat tarixi", con: false },
  ];
  const columns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
    },
    {
      title: 'İstifadəçi',
      dataIndex: "fullName",
      key: "3",
    },
    {
      title: 'Əməliyyat tarixi',
      dataIndex: "operationDate",
      key: "6",
      render: (i) => {
        return moment(i).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    {
      title: 'Əməliyyat adı',
      dataIndex: "operationName",
      key: "8",
    },
    {
      title: "",
      dataIndex: "id",
      key: "",
      render: (i, data) => {
        console.log(data);

        return (
          <div className="flex flex-end">
            {
              data.canRecovery &&
              <>
                <Tooltip className="ml-5" title={t("Bərpa")} placement="topRight">
                  <Button className="border-none" type="text" shape="circle" onClick={() => recovery(i)}>
                    <SyncOutlined />
                  </Button>
                </Tooltip>
              </>
            }
            <Tooltip
              className="ml-5"
              title={t("detailed")}
              placement="topRight"
            >
              <Link
                to={{
                  pathname: `/register/history/${id}/view/${data.id}`,
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EyeOutlined />
                </Button>
              </Link>
            </Tooltip>
          </div>
        );
      },
    },

  ]
  const recovery = (id) => {
    const params = {
      id: id
    }
    aictia.put(`HistoryOfCompany?id=${id}`).then((res) => {
      props.notify("Bərpa edildi", true);
      getHistory();
    }).catch((err) => {
      notify(err.response, false);
    });
  }

  // const getLog = () => {
  //   setSpin(true);
  //   const params = {
  //     PerDataCount: (page-1) * pageSize,
  //     GetDataCount: pageSize,
  //     UserName: filter?.UserName,
  //     RoleName: filter?.RoleName,
  //     OperationStartDate: filter?.OperationStartDate,
  //     OperationEndDate: filter?.OperationEndDate

  //   };

  //   aictia.get("HistoryOfCompany",{params}).then((res) => {
  //     res.data && setSpin(false);
  //     console.log(res);

  //     setLog(
  //       res.data.data.map((d, index) => {
  //         return {
  //           ...d,
  //           key: index + 1,
  //           index,
  //           tableIndex: index + 1 + (page-1)*pageSize,
  //         };
  //       })
  //     );
  //     setCount(res.data.count)
  //   });
  // };
  const onSearch = (values) => {
    const params = {
      ...values,
      StartDate: values.StartDate && values.StartDate[0].format("YYYY-MM-DD"),
      EndDate: values.StartDate && values.StartDate[1].format("YYYY-MM-DD"),
      companyId: id,
      PerDataCount: (page - 1) * pageSize,
      GetDataCount: pageSize,
    };
    setFilter(params);
    setSpin(true);
    aictia
      .get("HistoryOfCompany", { params })
      .then((res) => {
        setSpin(false);
        console.log(res);

        setHistory(
          res.data.data.map((d, index) => {
            return {
              ...d,
              key: index + 1,
              index,
              tableIndex: res.data.totalCount - index - (page - 1) * pageSize,
            };
          })
        );
        setCount(res.data.totalCount)
      })
      .catch((err) => {
        setSpin(false);
        notify(err.response, false);
      });
  };
  const clearFilter = () => {
    form.resetFields();
    getHistory();
    setInput(null);
    setFilter(null);
    //getLog();
  };
  const getHistory = () => {
    const params = {
      companyId: id,
      PerDataCount: (page - 1) * pageSize,
      GetDataCount: pageSize,
    }
    aictia.get("HistoryOfCompany", { params }).then((res) => {
      console.log(res);
      setHistory(
        res.data.data.map((d, index) => {
          return {
            ...d,

            key: index + 1,
            index,
            tableIndex: res.data.totalCount - index - (page - 1) * pageSize,
          };
        })
      );
      setCount(res.data.totalCount)
    });
  };

  const getUser = () => {
    aictia.get("User").then((res) => {
      setUser(res.data);
    });
  };

  useEffect(() => {
    getHistory();
    getUser();
  }, [page,t,pageSize]);

  // useEffect(() => {
  //   console.log("page",page);

  //  // getLog();
  // }, [page]);



  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading p-2 flex mt-0 bg-white">
            <div className="page-name">
              <Form onFinish={onSearch} layout="vertical" form={form}>
                <div className="commontask bg-white ">
                  <Row className={"mt-5"} gutter={[16, 8]}>
                    <Col md={11} sm={13} xs={24}>
                      <Form.Item
                        name={"StartDate"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <RangePicker size={"large"} style={{ width: '100%' }} placeholder={["Başlanğıc tarixi", "Son tarix"]} />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        name={"fullname"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select placeholder={t("İstifadəçi")}>
                          {user.map((p, index) => {
                            return (
                              <Option key={index} value={p.fullName}>
                                {p.fullName}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* <Col md={4} sm={12} xs={24}>
                      <Form.Item
                        name={"RoleName"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select placeholder={t("Səlahiyyət")}>
                          <Option value="Admin">Admin</Option>
                          <Option value="Inspector">Inspector</Option>

                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12} xs={24}>
                      <Form.Item
                        name={"CompanyName"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Input placeholder={t("Şirkətin adı")} size={"large"} />
                      </Form.Item>
                    </Col> */}
                    <Col md={4} sm={12} xs={24}>

                      <Button
                        type="primary"
                        size={"large"}
                        className="w-100 f-13"
                        htmlType="submit"
                      >
                        {t("search")}
                      </Button>

                    </Col>

                    <Col md={3} sm={12} xs={24}>
                      <Button
                        type="primary"
                        size={"large"}
                        onClick={() => {
                          clearFilter();
                        }}
                        className="w-100"
                        htmlType="submit"
                      >
                        <ClearOutlined />
                      </Button>

                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
            {/* <div>
                 <Link
              to={`/`}
            >
              <Button type={"primary"}>X</Button>
            </Link>
                  </div>
   */}
            <div className="register-head">
              <Link
                to={{
                  pathname: `/`,
                  state: { locales: null },
                }}
              >
                <Button type="primary">X</Button>
              </Link>
            </div>

          </div>
        </Col>
        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={columns}
                dataSource={convertColumns(history, cols)}
                pagination={{
                  pageSize: pageSize,
                  current: page,
                  showSizeChanger:true,
                  total: count,
                  onChange: (page,pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  },
                }}
              />
            </Col>
          )}
        </>
      </Row>
    </div>
  );
}

export default connect(null, { notify })(History);
