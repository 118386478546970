import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  AuditOutlined,
  FileDoneOutlined,
  UsergroupAddOutlined,
  BankOutlined,
  BarChartOutlined,
  SnippetsOutlined,
  SwitcherOutlined,
  LaptopOutlined,
  GatewayOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const MenuList = () => {
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState("")
  const rootSubmenuKeys = ["11", "21", "31",];


  useEffect(() => {
    // Mevcut sayfanın path'ini almak için:
    const currentPath = window.location.hash;
    
    if (currentPath.includes("/register") || currentPath.includes("/")) {
      setSelectedKey("11");
    } else if (currentPath.includes("/dashboard")) {
      setSelectedKey("21");
    } else if (currentPath.includes("/organization")) {
      setSelectedKey("31");
    }
  }, []);

  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };
  const role = localStorage.getItem("role");

  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      selectedKeys={[selectedKey]} 
      onOpenChange={onOpenChange}
      onClick={(e) => setSelectedKey(e.key)}
      className="menu-ul"
    >
      {role !== 'Organization' && 
          <Menu.Item key="11">
        <Link to={`/register`}>
          <span>
            <FileDoneOutlined />
            <span >Reyestr</span>
          </span>

        </Link>
      </Menu.Item> }
      {role !== 'Organization' && 
          <Menu.Item key="21">
          <Link to={`/dashboard`}>
            <span>
              <BarChartOutlined />
              <span >Statistika</span>
            </span>
  
          </Link>
        </Menu.Item> }

        {
          role === 'Organization' && 
          <Menu.Item key="31">
          <Link to={`/organization`}>
            <span>
              <GatewayOutlined />
              <span >{t('myCabinet')}</span>
            </span>
  
          </Link>
        </Menu.Item>
        }
     
    </Menu>
  );
};

const mapStateToProps = ({ user, stock }) => {
  return {
    // perms: user.data.userPermissions, stock 
  };
};

export default connect(mapStateToProps, {})(MenuList);


