export let tr;
tr = {
  // sidebar links
  admin: "İnzibatçı",
  positions: "Vəzifələr",
  permissions: "Səlahiyyətlər",
  users: "İstifadəçilər",
  workers: "İşçilər",
  myCabinet: "Kabinem",
  // bottom menu lang and settings
  langSelection: "Dil seçimi",
  support: "Destek",
  instruction: "Kullanım kuralları",
  termsOfUse: "Kullanım şartları",
  feedback: "Geri bildirim",
  dakMode: "Qaranlıq Mod",
  TypeOfProject: "Layihənin növü",
  TypeOfStartup: "Startapın növü",
  TypeOfInnovation: "İnnovasiyanın növü",
  TypeOfInvention:"ixtiranın növü",
  TypeOfProduct:"Məhsulun növü",
//kabinet

typeOfActivity: "Faaliyet türü",
fieldOfActivity: "Faaliyet alanı",
nameOfEntity: "Konunun adı",
officialRepresentative: "Resmi temsilci",
field:'Alan',
tin: "VÖEN",
  // validationMessages
  inputError: "Xana doldurulmalıdır",
  dateError: "Tarix seçilməlidir",
  mailError: "Mail düzgün daxil edilməlidir",
  
  companyInfo: "Şirket ve faaliyetleri hakkında bilgi",
  company: "Şirket",
  businessEntityName: "Ticari işletme adı",
  businessEntity: "Girişimci subjesi",
  actualAddress: "Gerçek adres",
  legalAddress: "Hukuki adres",
  employeeCount: "Çalışan sayısı",
  registrationDate: "Kayıt tarihi",
  officialRepresentative: "Konunun resmi temsilcisi",
  trademark: "Satış markası",
  statePrivate: "Kamu/Özel",
  officialWebsite: "Resmi web sitesi",
  charterCapital: "Sermaye",
  recognizedLeader: "Tanınmış lider",
  membershipPackage: "Üyelik paketi",
  directorPhone: "Yöneticinin telefon numarası",
  ceoEmail: "Ceo maili",
  companyPhone: "Şirket telefon numarası",
  officialEmail: "Resmi e-posta",
  companySocialMedia: "Şirketin sosyal medyası",
  directorSocialMedia: "Liderin sosyal medyası",
  note: "Not",
  positions: "Pozisyonlar",
  permissions: "Yetkiler",
  users: "Kullanıcılar",
  workers: "Çalışanlar",
  feedback: "Geri bildirim",
  // common page headings
  name: "Adı",
  fullName: "Tam adı",
  fullnameLabel: "Ad, soyad, ata adı",
  officalName: "Rəsmi adı",
  phone: "Telefon",
  contactNumber: "Əlaqə nömrəsi",
  country: "Ülke",
  city: "Şəhər",
  adress: "Ünvan",
  industry: "Sənaye",
  relatedPerson: "Əlaqəli şəxs",
  eMail: "E-poçt",
  email: "Email",
  addTo: "Əlavə et",
  edit: "Düzenle",
  delete: "Sil",
  save: "Kaydet",
  cancel: "İptal et",
  approved: "Təsdiq edilib",
  accepted: "Qəbul edilib",
  doConfirm: "Təsdiq et",
  canceled: "Ləğv edilib",
  onWait: "Gözləyən",
  close: "Bağla",
  type: "Tür",
  typeOf: "Növü",
  additionalNote: "Əlavə qeyd",
  quantity: "Miqdar",
  quantityOf: "Miqdarı",
  productName: "Məhsulun adı",
  product: "Məhsul",
  heading: "Başlıq",
  detailed: "Detaylı bak",
  detailedInfo: "Ətraflı məlumat",
  selectDate: "Tarixi seçin",
  areYouSure: "Silmək istədiyinizə əminsinizmi ?",
  yes: "Bəli",
  no: "Xeyr",
  // positions page
  responsibility: "Cavabdehlik",
  positionName: "Vəzifənin adı",

  // permissions page
  authorityGroups: "Səlahiyyət qrupları",
  read: "Oxumaq",
  write: "Yazmaq",
  editTo: "Düzenle",
  deleteTo: "Silmək",

  // userspage
  username: "Kullanıcı adı",
  newPassword: "Yeni şifrə",
  worker: "İşçi",
  positionGroup: "Vəzifə qrupu",
  // areas page
 
  company: "Şirkət",
  position: "Vəzifə",

  // client-and-consumers
  allOf: "Hamısı",
  status: "Status",

  fin: "Ş/V fin kodu",
  seriaNo: "Ş/V seriya nömrəsi",
  birthdate: "Doğum tarixi",
  sex: "Cinsi",

  // warehouse/purchases
  changeStatus: "Statusu dəyiş",
  dateToBuy: "Alınma tarixi",
  endDate: "Bitmə tarixi",
  startDate: "Başlama tarixi",
  price: "Qiymət",
  finalPrice: "Yekun qiymət",
  search: "Ara",
componayInformation:'Информация о компании и её деятельности',


  // warehouse/purchases-on-wait
  using: "İstifadə",
  operation: "Əməliyyat",

  // eservespage
  acceptedDate: "Qəbul tarixi",
  // daily page
  date: "Tarix",

  // new added words
  savedMessage: "Dəyişikliklər yadda saxlanıldı",
  addPasswordError: "Şifrəni təyin edin",
  minimumPasswordError: "Şifrə minimal 6 simvol olmalıdır",
  workerMustSelectError: "İşçi seçilməlidir",
  permissionMustSelectError: "Səlahiyyət növü seçilməlidir",
  itemMustSelectError: "Maddə seçilməlidir",
  measurementUnitMustSelectError: "Ölçü vahidi seçilməlidir",
  typeMustBeSelected: "Növ seçilməlidir",
  positionMustSelectError: "Vəzifə seçilməlidir",
  regionMustSelectError: "Region seçilməlidir",
  categoryMustSelectError: "Kateqoriya seçilməlidir",
  selectCropSort: "Sahə növü seçilməlidir",
  selectCrop: "Sahə seçilməlidir",
  addNewArea: "Yeni sahə əlavə et",
  crops: "Crops",
  successMessage: "Əməliyyat uğurlu oldu.",
  errMessage: "Əməliyyat uğursuz oldu.",
  errorMessage: "Xəta baş verdi",
  workerDeleted: "İşçi sistemdən silindi.",
  workerIsAdded: "Yeni işçi yaradıldı.",
  addNewWorker: "Yeni işçi əlavə et",
  man: "Kişi",
  woman: "Qadın",
  thereIs: "Var",
  thereNo: "Yoxdur",
  notifications: "Bildirişlər",
  login: "Giriş yap",
  register: "Qeydiyyatdan keç",
  personCount: "nəfər",
  logOut: "Çıkış",
  password: "Şifre",
  EMailFull: "Elektron poçt",
  // permissions
  administrator: "İnzibatçı",
  create: "Əlavə etmək",
  update: "Yeniləmək",

  links: {
    admin: "inzibatci",
    positions: "vezifeler",
    permissions: "selahiyyetler",
    users: "istifadeciler",
    workers: "ishciler",
    history: "tarixce",
    // new added
    reports: "hesabatlar",
  },
  // layout pages
  // home
 
  

  remember:'Yadda saxla',
  entry: 'GİRİŞ',
};


